/* You can add global styles to this file, and also import other style files */


@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.0/font/bootstrap-icons.css");

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap');

/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootswatch/dist/darkly/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootswatch/dist/darkly/bootswatch";

.clickable {
    cursor: pointer;
}

button i {
    padding-right: 0.5rem;
}

.gap-after {
    padding-right: 1rem;
}

.score-viewport .cdk-virtual-scroll-content-wrapper {
    display: flex;
    flex-direction: row;
  }
  
.showToolbar {
    margin-top: 60px ! important;
}